<template>
    <div style="min-width: 1360px;">
        <Header/>
        <headerBanner :content="bannerContent" :price="bannerPrice" @btnClick="bannerClick" title="OMS系统" imgStyle="height:0%;height:0" contentStyle="width:100%" :bodyStyle="bodyStyle"/>
        
        <div style="padding: 60px 0;background-color: rgba(247, 248, 254, 1);">
            <div class="card--title" style="margin-bottom: 40px;">QMS系统能为你提供</div>
            <div class="omsform-list">
                <div class="omsform-card" v-for="(item,i) in cardList" :key="i"
                    style="background-color: #fff;border: 1px solid #DBE0FF;height: 160px;">
                    <div class="omsform-card-title">{{ item.title }}</div>
                    <div class="omsform-card-content" v-html="item.content"></div>
                </div>
            </div>
        </div>

        <div class="card">
            <div class="card-container">
                <div class="card--title" style="margin-bottom: 40px;">OMS系统为产业赋能</div>
                <div class="card-boxs">
                <div style="width: 450px;">
                    <div class="cell-item" v-for="card of cardList2" :key="card.id">
                        <div class="or-title">{{card.title}}</div>
                        <div class="orContent" >{{card.content}}</div>
                    </div>
                </div>
                <img :src="require('@/assets/img/home/oms.png')" style="width: 710px;height: 620px;">
                </div>
            </div>
        </div>

        <applyTrial ref="dialog" :servicePlatform="3"/>
        <new-cloude-footer/>
    </div>
</template>
<script>
import Header from "@/views/home/newCloudeHeader.vue";
import headerBanner from '@/components/assembly/headerBanner';
import applyTrial from "@/components/assembly/applyTrial";
import newCloudeFooter from "@/views/home/newCloudeFooter.vue";
export default {
    name: 'MESsystem',
    components: {
        Header,
        headerBanner,
        applyTrial,
        newCloudeFooter
    },
    data() {
        return {
            bannerPrice:'市场价格：240000元',
            bannerContent: 'OMS系统是一套为企业设计的电子商务订单处理软件，旨在帮助企业高效管理在线和离线订单流程。该系统支持订单接收、处理、配送以及退换货等全方位服务，同时提供库存管理、客户关系管理和数据分析等功能，确保企业能够快速响应市场变化，提升顾客满意度，并优化运营效率',
            bodyStyle: `background-image:url(${require('@/assets/img/home/WDKI0LH_y.png')});background-size: contain;background-repeat: no-repeat;background-position: right 25px;`,
            cardList:[
                {
                    title:'订单处理',
                    content:'自动化订单输入、确认、分配和处理流程'
                },
                {
                    title:'库存管理',
                    content:'实时更新库存信息，自动扣减和补充库存'
                },
                {
                    title:'多渠道销售',
                    content:'集成多个销售渠道，如自建网站、电商平台、社交媒体等'
                },
                {
                    title:'客户管理',
                    content:'记录和管理客户信息，包括购买历史、偏好设置和联系资料'
                },
                {
                    title:'财务管理',
                    content:'跟踪订单相关的收入、支出和财务报告'
                },
                {
                    title:'物流集成',
                    content:'与主要物流公司的API集成，实现自动发货和跟踪'
                },
                {
                    title:'退货管理',
                    content:'简化退货流程，包括退货授权、商品检查和退款处理'
                },
                {
                    title:'报表分析',
                    content:'生成销售、库存、客户和财务相关的详细报表'
                },
                {
                    title:'移动设备支持',
                    content:'支持通过移动设备进行订单查询和管理'
                },
                {
                    title:'定制化服务',
                    content:'根据企业特定需求定制开发特殊功能和服务'
                },
            ],
            cardList2:[
                {
                    title:'效率提升',
                    content:'自动化流程减少手动操作，提高工作效率和响应速度'
                },
                {
                    title:'实时数据',
                    content:'实时数据同步保证信息的一致性和准确性'
                },
                {
                    title:'交互友好',
                    content:'直观的用户界面设计，易于学习和使用'
                },
                {
                    title:'灵活定制',
                    content:'可根据不同企业需求定制功能和流程'
                },
                {
                    title:'系统集成',
                    content:'与ERP、CRM、WMS等其他系统无缝集成，实现信息共享'
                },
                {
                    title:'扩展升级',
                    content:'可随着企业规模扩大而进行系统升级和功能扩展，无后顾之忧'
                },
            ],
        }
    },
    methods: {
        
    }
}
</script>
<style lang="scss" scoped>

.card--title{
  font-size: 30px;
  font-weight: bold;
  text-align: center;
  color: #333;
}

.omsform-list {
  display: flex;
  align-items: center;
  width: 1200px;
  margin: auto;
  justify-content: space-between;
  margin-top: 40px;
  flex-wrap: wrap;

  .omsform-card {
    width: 590px;
    height: 260px;
    padding: 34px 30px 0;
    box-sizing: border-box;
    margin-bottom: 20px;

    &-title {
      font-size: 18px;
      font-weight: bold;
      margin-bottom: 18px;
    }

    &-content {
      font-size: 16px;
      color: #666;
    }

  }
}

.card {
  padding: 60px 0;
  &-container {
    width: 1200px;
    margin: auto;
  }
}

.card-boxs{
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.or-title {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 15px;
  color: #333;
  margin-top: 15px;
}

.orContent {
  padding-bottom: 24px;
  line-height: 1.5;
  text-align: justify;
  font-size: 14px;
  color: #333;
}

.cell-item {
  border-bottom: 1px dashed #1935DE;
  &:last-child {
    border: 0;
  }
}



</style>